<div
  [@fadeIn]
  class="container"
>
  <div class="content px-2">
    <div class="user  ">
      <div class="">
        <h2
          class="chip title-user w-full text-white"
          [style.background]="theme.get('backgroundSec')"
        >
          Datos de usuario
        </h2>

      </div>

      <form [formGroup]="form" (ngSubmit)="saveData()" class="checkout-form">
        <mat-form-field appearance="outline" class="w-full" color="primary">
          <mat-label>Nombre Completo</mat-label>
          <input formControlName="name" type="text" matInput />
          <mat-error>Este campo es requerido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full" color="primary">
          <mat-label>Telefono</mat-label>
          <input formControlName="phone" type="number" matInput />
          <mat-error>Este campo es requerido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full" color="primary">
          <mat-label>Direccion</mat-label>
          <input formControlName="direction" type="text" matInput />
          <mat-error>Este campo es requerido</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Referencia de la casa (opcional)</mat-label>
          <textarea
            formControlName="reference"
            matInput
            placeholder="Ej. Casa color rojo, porton de madera"
          ></textarea>
          <mat-error>Este campo es requerido</mat-error>
        </mat-form-field>

        <p class="alert-data alert" [style.color]="theme.get('background')">
          Todos los datos que recolectamos están protegidos y son utilizados
          únicamente para mejorar su experiencia en nuestra aplicacion.
        </p>

        <button
          class="button-save text-white"
          [style.background]="theme.get('background')"
          type="submit"
        >
          Guardar
        </button>
      </form>
    </div>
  </div>
</div>
