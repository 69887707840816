<div  *ngIf="layoutState.stateSubject|async as state">
  <a [routerLink]="['cart']">

  <div *ngIf="countitemsCart && state.navigation" class="bottom-nav md:hidden " [style.background]="theme.get('backgroundSec')">
    
    <div class="container-nav" >
      <nav class="navigation">
        <ul class="px-3  d-flex justify-content-between">
  
          <!-- <li class="item-nav">
            <a [routerLink]="[routeService.getOrigin()]">
              <div
                class="grid-menu"
                [class.active-home]="currentRoute === 'home'"
                [style.background]=" currentRoute === 'home' ? theme.get('background') : '' ">
                <div></div>  
                <div></div>
                <div></div>
                <div></div>
              </div>
  
            </a>
          </li> -->

          <!-- <li class="item-nav">
            <a [routerLink]="['user']">
              <i [class.item-active]="currentRoute === 'user'" [style.color]="currentRoute === 'user' ? theme.get('background') : '' " class="pi-user pi icon-nav"></i>
            </a>
          </li> -->


          <li class="d-flex gap-2 relative">
            <a routerLink="cart" class="text-white font-medium">
              Ver pedido
            </a>

          </li>
  
  
          <li class="item-nav">
            <a [routerLink]="['cart']">
                <span class="d-flex relative gap-2 border-round text-white" *ngIf="totalCart else cartEmpty">
                  
                  <!-- <span class="cart-counter font-bold text-white" [ngStyle]="{'background':theme.get('background')}">
                    {{countitemsCart}}
                  </span>  -->
                  <span class="font-bold text-lg border-right-1 roboto border-white pr-2">
                    {{totalCart|currency}}
                  </span>
                  
                  <span class="relative">
                    <i class="pi icon-bag pi-shopping-bag font-bold text-white"></i>
                    <span class="cart-counter font-bold text-white " [ngStyle]="{'background':theme.get('background')}">
                      {{countitemsCart}}
                    </span> 
                  </span>
 
                  <!-- <i class="pi icon-bag pi-shopping-bag font-bold"></i> -->

                </span>
              
                <ng-template #cartEmpty>
                  
                                <div class="cart-container">
                                  <i [class.item-active]="currentRoute === 'cart'" [style.color]="currentRoute === 'cart' ? theme.get('background') : '' " class="pi pi-shopping-bag icon-nav"></i>
                                </div>

                </ng-template>
            </a>
          </li>
        </ul>
  
      </nav>
    </div>

  </div>
</a>

</div>
