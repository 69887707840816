<div class="p-5 md:w-22rem" *ngIf="localService.local$ |async as local">
    <div class="cardModal">

        <div class="cardHeader mb-2">
            <h2 class="text-xl">{{local.name}}</h2>
        </div>


        <div class="cardBody">
            <span class="d-flex mb-1 font-semibold">
                <i class="pi pi-whatsapp  mr-2"></i>
                {{local.phone}}
            </span>

            <span class="d-flex font-semibold mb-2" *ngIf="local.schedules">
                <i class="pi pi-clock mr-2"></i>
                <span>Horarios</span>
            </span>

            <ul *ngIf="local.schedules">
                <li  *ngFor="let day of local.schedules.days">
                    <span class="mb-1 border-bottom-1 border-300 d-flex gap-3 justify-content-between">
                        <span class="d-flex gap-2">
                            <i class="pi pi-circle-fill text-sm" [ngClass]="day.open ? 'text-green-500' : 'text-red-500'"></i>
                            {{day.name | uppercase}}
                        </span>

                        <span class="">
                            <span *ngIf="!day.shifts.length && day.open">Abierto las 24hs</span>
                            <div class="" *ngIf="day.open && day.shifts.length">
                                <span class="block" *ngFor="let shift of day.shifts">
                                    {{shift.start}} - {{shift.end}}
                                </span>
                            </div>

                            <span *ngIf="!day.open">Cerrado</span>
                        </span>

                    </span>
                </li>
            </ul>


        </div>
    </div>


    <button mat-button class="mt-3" [mat-dialog-close]="true">Cerrar</button>
</div>